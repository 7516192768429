<template>
  <div class="a">
    <my-custom-loader v-if="showLoader && !cancelPaydunya" />
    <success-funding-wallet
      v-if="!showLoader && !cancelPaydunya"
      @closeSuccessFunding="closeSuccessFunding"
    />
    <cancel-paydunya
      v-if="cancelPaydunya && !showLoader"
      @closeSuccessFunding="closeSuccessFunding"
    />
  </div>
</template>

<script>
import { firebaseDb } from '../main'
import { firebaseRef } from '../rema-function/firebaseRef'
import MyCustomLoader from '../components/helper/rema-components/my-custom-loader'
import SuccessFundingWallet from '../components/helper/rema-components/success-funding-wallet'
import firebase from 'firebase'
import CancelPaydunya from '../components/helper/rema-components/cancel-paydunya'
export default {
  name: 'Index',
  components: { CancelPaydunya, SuccessFundingWallet, MyCustomLoader },
  data () {
    return {
      showLoader: true,
      cancelPaydunya: false,
      latestCode: 0,
      id: this.$route.params.id,
      idBefore: this.$route.params.idBefore,
      oprToken: this.$route.query.token,
      isOnSite: this.$route.params.isOnSite,
      beforeData: {}
    }
  },
  created () {
    let vm = this
    let payDunya = require('paydunya')
    let setup = new payDunya.Setup({
      masterKey: 'mlbbrcjJ-RgO0-RSR3-1sXa-xCH1RKKutNkf',
      privateKey: 'live_private_thUsHtOyltSfQ0C3n4undcuAgXE',
      publicKey: 'live_public_FVZ6UDwkIEI5nuF5ERsUl4r8bTA',
      token: 'Qwj77zkpY9uz3LCOV1sC'
      // mode: 'live' // optional. use in sandbox mode.
    })
    let store = new payDunya.Store({
      name: 'REMA Medical Technologies'
    })
    if (this.isOnSite === '1') {
      var invoice = new payDunya.CheckoutInvoice(setup, store)
      invoice.confirm(this.oprToken)
        .then(() => {
          if (invoice.status === 'completed') {
            firebaseDb.ref(firebaseRef.wallets).child('lastWalletFundingId').once('value', function (snapshot) {
              vm.latestCode = snapshot.val()
              firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid).child('/' + firebaseRef.walletsWaiting)
                .child(vm.idBefore).once('value', function (snapshot) {
                  if (snapshot.exists()) {
                    vm.beforeData = snapshot.val()
                    let recharge = {
                      type: 'visa',
                      date: new Date().getTime(),
                      amount: vm.beforeData.amount,
                      ref: vm.latestCode
                    }
                    let ref = firebaseDb.ref(firebaseRef.wallets + firebase.auth().currentUser.uid)
                    firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid)
                      .once('value', function (snapshot) {
                        if (snapshot.child('balance').exists()) {
                          let current = snapshot.val()
                          current.balance = parseFloat(current.balance) + parseFloat(recharge.amount)
                          current.history.push(recharge)
                          if (ref.update(current)) {
                          // vm.findCode.isValid = false
                            vm.showLoader = false
                            firebaseDb.ref(firebaseRef.wallets).child('lastWalletFundingId').set(vm.latestCode + 1)
                            firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid).child('/' + firebaseRef.walletsWaiting)
                              .child(vm.idBefore).remove()
                          } else {
                            alert('no there')
                          }
                        } else {
                          var first = {
                            balance: vm.beforeData.amount,
                            history: []
                          }
                          first.history.push(recharge)
                          if (ref.set(first)) {
                            vm.showLoader = false
                            firebaseDb.ref(firebaseRef.wallets).child('lastWalletFundingId').set(vm.latestCode + 1)
                            firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid).child('/' + firebaseRef.walletsWaiting)
                              .child(vm.idBefore).remove()
                          }
                        }
                      })
                  } else {
                    alert('error')
                  }
                })
            })
          } else {
            vm.showLoader = false
            vm.cancelPaydunya = true
          }
        })
        .catch((err) => {
          alert(err.toString())
        })
    } else {
      firebaseDb.ref(firebaseRef.wallets).child('lastWalletFundingId').once('value', function (snapshot) {
        vm.latestCode = snapshot.val()
        firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid).child('/' + firebaseRef.walletsWaiting)
          .child(vm.idBefore).once('value', function (snapshot) {
            if (snapshot.exists()) {
              vm.beforeData = snapshot.val()
              let recharge = {
                type: 'visa',
                date: new Date().getTime(),
                amount: vm.beforeData.amount,
                ref: vm.latestCode
              }
              let ref = firebaseDb.ref(firebaseRef.wallets + firebase.auth().currentUser.uid)
              firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid)
                .once('value', function (snapshot) {
                  if (snapshot.child('balance').exists()) {
                    let current = snapshot.val()
                    current.balance = parseFloat(current.balance) + parseFloat(recharge.amount)
                    current.history.push(recharge)
                    if (ref.update(current)) {
                    // vm.findCode.isValid = false
                      vm.showLoader = false
                      firebaseDb.ref(firebaseRef.wallets).child('lastWalletFundingId').set(vm.latestCode + 1)
                      firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid).child('/' + firebaseRef.walletsWaiting)
                        .child(vm.idBefore).remove()
                    } else {
                      alert('no there')
                    }
                  } else {
                    var first = {
                      balance: vm.beforeData.amount,
                      history: []
                    }
                    first.history.push(recharge)
                    if (ref.set(first)) {
                      vm.showLoader = false
                      firebaseDb.ref(firebaseRef.wallets).child('lastWalletFundingId').set(vm.latestCode + 1)
                      firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid).child('/' + firebaseRef.walletsWaiting)
                        .child(vm.idBefore).remove()
                    }
                  }
                })
            } else {
              alert('error')
            }
          })
      })
    }
  },
  methods: {
    closeSuccessFunding () {
      this.$router.push('/histories')
    }
  },
  firebase () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
